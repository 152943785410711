


























import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import SearchBarComponent from '@/components/shared/SearchBar.component.vue';
import ToggleButtonGroupComponent from '@/components/shared/ToggleButtonGroup.component.vue';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');
const AuthStore = namespace('auth');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    ToggleButtonGroupComponent,
    SearchBarComponent,
    UserManageComponent: () => import(
        /* webpackChunkName: "UserManageComponent" */
        '@/components/user/UserManage.component.vue'),
    UserTableComponent: () => import(
        /* webpackChunkName: "UserTableComponent" */
        '@/components/user/UserTable.component.vue'),
  },
})
export default class UsersOverviewView extends Vue {

  @UserStore.Getter('users')
  private _users!: User[];
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: {companyId: string, populate?: string[]}) => Promise<User[]>;
  @AuthStore.Getter('user')
  private _user!: User;
  @UserStore.Mutation('storeActiveUser')
  private activeUserMutation!: (user: User) => any;
  private selectedFilter: string = 'active';
  private searchString: string = '';
  private showCreateUserModal: boolean = false;

  private userToEdit: User = new User();

  /**
   * Filters the Users according to the value in the searchbar and the statusfilter
   */
  get filteredUsers(): User[] {
    let filteredUsers = this.users.filter((user) =>
        // compares firstnames with the input of the searchbar
        user.firstName && user.firstName
            .toLowerCase().includes(this.searchString.toLowerCase()) ||
        // compares lastnames with the input of the searchbar
        user.lastName && user.lastName
            .toLowerCase().includes(this.searchString.toLowerCase()) ||
        // compares email with the input of the searchbar
        user.email && user.email
            .toLowerCase().includes(this.searchString.toLowerCase()));

    // filters for status
    if (this.selectedFilter !== 'all') {
      if (this.selectedFilter === 'active') {
        filteredUsers = filteredUsers.filter((user) => user.active);
      } else {
        filteredUsers = filteredUsers.filter((user) => !user.active);
      }
    }
    return filteredUsers;
  }

  get users(): User[] {
    return this._users;
  }

  get user(): User {
    return this._user;
  }

  private onEditUser(user: User = new User()) {
    this.userToEdit = user;
    this.showCreateUserModal = true;
  }

  /**
   * Editing a User
   */
  public async onExitModal() {
    // Update User on Modal Close
    this.showCreateUserModal = false;
    await this.loadUsersAction({companyId: this.$route.params.companyId, populate: ['role']});
  }

  private async mounted() {
    try {
      await this.loadUsersAction({companyId: this.$route.params.companyId, populate: ['role']});
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
